import React from 'react';
import Layout from '@components/Layout/Layout';
import TextHero from '@components/TextHero';
import { graphql } from 'gatsby';
import {
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
  PlusQuery,
} from '../../../graphql-types';

import SEO from '@components/seo';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import Container from '@src/components/Container';
import Grid, { Row } from '@src/components/Grid';
import SmsDownloadForm from '@src/components/SmsDownloadForm/SmsDownloadForm';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import Card, { CardImage } from '@src/components/Card';
import UspList, { UspListItem } from '@src/components/UspList/UspList';

import PrimaryButton from '@src/components/PrimaryButton/PrimaryButton';
import useIsMobile from '@src/hooks/useIsMobile.hook';
import { AppDownloadUrl } from '@src/pageConstants';
import * as ThirdPartyTracking from '../../lib/thirdPartyTracking';

import paketPages from '@src/data/paketPages';
import useDialogRoute from '@src/components/Dialog/useDialogRoute.hook';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import { GatsbyImage } from 'gatsby-plugin-image';
import Badge from '@src/components/Badge/Badge';

type PlusProps = {
  data: PlusQuery;
};

function Plus({ data }: PlusProps) {
  const {
    contentfulPage: {
      headLine,
      pageHeader,
      pageHeaderDescription: { pageHeaderDescription },
      pageHeaderSubtitle: { pageHeaderSubtitle },
      pageContent,
      heroImage,
    },
  } = data;

  const uspList = (pageContent[0] as ContentfulTextList).advancedTextListItems;

  const isMobile = useIsMobile();

  const cardImage = useContentfulImage(heroImage);

  const dlg2 = useDialogRoute('kort');
  const dlg2Card = pageContent[2] as ContentfulCard;
  const dlg2Data = pageContent[3] as ContentfulDialogContent;
  const dlg2Img = useContentfulImage(dlg2Card.backgroundImage);
  dlg2Img.objectPosition = '52% 60%';

  const img2 = (
    <GatsbyImage
      image={dlg2Img.imageData}
      alt={dlg2Img.title}
      style={{
        position: 'absolute',
        bottom: '-8%',
        width: '80%',
        left: '32%',
      }}
    />
  );

  const dlg3 = useDialogRoute('kvitton');
  const dlg3Card = pageContent[4] as ContentfulCard;
  const dlg3Data = pageContent[5] as ContentfulDialogContent;
  const receiptImg = useContentfulImage(dlg3Card.backgroundImage);

  const dlg4 = useDialogRoute('cashback');
  const dlg4Card = pageContent[6] as ContentfulCard;
  const dlg4Data = pageContent[7] as ContentfulDialogContent;
  const cashbackImg = useContentfulImage(dlg4Card.backgroundImage);

  const dlg5 = useDialogRoute('spara');
  const dlg5Card = pageContent[8] as ContentfulCard;
  const dlg5Data = pageContent[9] as ContentfulDialogContent;
  const saveImg = useContentfulImage(dlg5Card.backgroundImage);

  return (
    <Layout theme="white" subNavLinks={paketPages}>
      <SEO title={headLine} />
      <Container style={{ marginTop: 52 }}>
        <Grid spacedRows>
          <TextHero
            theme="white"
            title={pageHeader}
            subtitle={pageHeaderSubtitle}
            largeSubtitle
            leftAligned
          >
            <div style={{ marginTop: '-0.4rem', marginBottom: '2rem' }}>
              <Badge color="green-soft">1 månad gratis</Badge>
            </div>
            {pageHeaderDescription}
            <div style={{ marginTop: 32 }}>
              <UspList theme="purple" items={uspList as UspListItem[]} />
            </div>
            {isMobile && (
              <PrimaryButton
                style={{ marginTop: 32 }}
                href={AppDownloadUrl}
                onClick={ThirdPartyTracking.cardSignup}
              >
                Skaffa Plus
              </PrimaryButton>
            )}
          </TextHero>
          {!isMobile && (
            <Card centerContent backdropImage={cardImage} noBorder>
              <SmsDownloadForm
                formProps={{ redirectToAppstore: true }}
                title="Skaffa Rocker Plus idag"
              />
            </Card>
          )}
          <Card
            title={dlg2Card.title}
            text={dlg2Card.subtitle}
            cta={dlg2Card.linkText}
            onClick={dlg2.openDialog}
            noShading
            theme="soft"
            noBorder
          >
            <CardImage imageData={dlg2Img} />
          </Card>
          <Card
            title={dlg3Card.title}
            text={dlg3Card.subtitle}
            cta={dlg3Card.linkText}
            onClick={dlg3.openDialog}
          >
            <CardImage>
              <div style={{ padding: 24 }}>
                <GatsbyImage
                  image={receiptImg.imageData}
                  alt={receiptImg.title}
                />
              </div>
            </CardImage>
          </Card>
          <Card
            title={dlg4Card.title}
            text={dlg4Card.subtitle}
            cta={dlg4Card.linkText}
            onClick={dlg4.openDialog}
          >
            <CardImage>
              <GatsbyImage
                image={cashbackImg.imageData}
                alt={cashbackImg.title}
              />
            </CardImage>
          </Card>
          <Card
            title={dlg5Card.title}
            text={dlg5Card.subtitle}
            cta={dlg5Card.linkText}
            theme="soft"
            noBorder
            onClick={dlg5.openDialog}
          >
            <CardImage imageData={saveImg} />
          </Card>
        </Grid>
      </Container>

      <CardPackageInfo />

      <ContentfulDialog
        visible={dlg2.isOpen}
        onClose={dlg2.closeDialog}
        contentfulData={dlg2Data}
      />
      <ContentfulDialog
        visible={dlg3.isOpen}
        onClose={dlg3.closeDialog}
        contentfulData={dlg3Data}
      />
      <ContentfulDialog
        visible={dlg4.isOpen}
        onClose={dlg4.closeDialog}
        contentfulData={dlg4Data}
      />
      <ContentfulDialog
        visible={dlg5.isOpen}
        onClose={dlg5.closeDialog}
        contentfulData={dlg5Data}
      />
    </Layout>
  );
}

export default Plus;

export const query = graphql`
  query Plus($locale: String) {
    contentfulPage(
      pageUrl: { eq: "/paket/plus" }
      node_locale: { eq: $locale }
    ) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      pageHeaderSubtitle {
        pageHeaderSubtitle
      }
      heroImage {
        title
        gatsbyImageData(width: 512)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulCardsSectionComponent {
          id
          entryTitle
          card {
            title
            subtitle
            linkPath
            linkText
          }
        }
        ... on ContentfulFaqSection {
          id
          title
          paragraph {
            paragraph
          }
          questions {
            question
            title
            overline
            answer {
              answer
            }
          }
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulTextSection {
              title
              content {
                content
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
